import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildQaqcUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { QAQC_CHART_TYPES } from 'state-domains/constants/mxdeposit';
import { sendRetrieveRequest } from 'state-domains/utils';

import { ChartFilters, ConvertedZScoreData, ReportsData } from '../types';

import { formatChartData } from './utils';

export const loadChartData = (
    chartType: QAQC_CHART_TYPES,
    requiredFilters: ChartFilters,
    optionalFilters: FilterObject[],
): Observable<ConvertedZScoreData[] | ReportsData[]> => {
    const obs = sendRetrieveRequest(
        buildQaqcUrlWithFilters(chartType, requiredFilters, optionalFilters),
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<{ data: ReportsData[] }>) => {
            const { data } = convertToCamel<{ data: ReportsData[] }>(response);
            const convertedResponse = formatChartData(chartType, data);
            return observableOf(convertedResponse);
        }),
    );
};
